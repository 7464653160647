.home-container .typewriter-container h1 {
  font-family: 'Courier New', monospace;
  font-size: 2em;
  text-align: center;
}

/* Style for the entire Home component */
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
}

/* Style for the typewriter text and cursor */
.typewriter-container h1 {
  font-family: 'Courier New', monospace; /* Typewriter font */
  font-size: 2em;
  text-align: center; /* Centers the text horizontally */
}
