.header-container {
  color: black;
  padding: 10px 20px; /* Padding around the content */
  display: flex;
  justify-content: space-between; /* Space out the logo and navigation links */
  align-items: center;

}

.header-logo {
  font-size: 1.5em; /* Larger text for the logo */
  font-weight: bold;
}

.header-nav {
  display: flex;
}

.header-nav a {
  color: black;
  text-decoration: none;
  margin-left: 20px; /* Space between links */
  font-size: 1em;
  font-weight: bold;
}

.header-nav a:hover {
  color: #cccccc; /* Light grey for hover state */
}

.header-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent; /* or any color you prefer */
  text-align: center;
  padding: 10px 0; /* Adjust padding to your preference */
  z-index: 1000; /* Ensures the header stays on top of other content */
}
.header-container {
  padding: 10px 20px; /* Adjust these values as needed */
  width: calc(100% - 40px); /* Adjust width based on the padding */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Keeps it on top */
}



